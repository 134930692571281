// `frontend/src/pages/Login.js`
import React from 'react';
import AuthForm from '../components/AuthForm';
import {useNavigate} from 'react-router-dom';
import axiosApi from "../util/AxiosRequests";
import TopNavigation from "../components/TopNavigation";

function Login() {
    const navigate = useNavigate();

    const handleLogin = async (user) => {
        axiosApi.post('/users/login', {
                username: user.username,
                password: user.password
            }
        ).then(response => {
            alert('Login successful');
            navigate('/v2/home');
        }).catch(error => {
            alert('Login failed');
            console.error(error);
        });
    };

    return(
        <div>
            <TopNavigation/>
            <AuthForm onSubmit={handleLogin} buttonText="Login"/>
        </div>
    );
}

export default Login;