import React from 'react';
import './css/TopNavigation.css';
import logo from '../Resource/logo.png';

function TopNavigation(){

    return (
        <div className={"topNavigation"}>
            <ul>
                <li > <img src={logo} alt={"logo"} style={{ maxHeight: '60px', objectFit: 'contain' }}/> </li>
                <li><a href="/v2">Home</a></li>
                <li><a href="/v2/detail?newpost=true">New Post</a></li>
                <li><a href="/v2/detail">All Item</a></li>
                <li><a href="/v2/detail?tag=myitem">My Item</a></li>
                <li><a href="/v2/detail?tag=wantbuy">Want Buy</a></li>
                <li><a className="active" href="/v2/login">User</a></li>
            </ul>
        </div>
    );
}

export default TopNavigation;