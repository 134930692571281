import './css/Detail.css';
import TopNavigation from "../components/TopNavigation";
import {useNavigate} from "react-router-dom";
import {forwardRef, useEffect, useRef, useState} from "react";
import DetailInfo from "../components/DetailInfo";
import NewPost from "../components/NewPost";
import axios from "axios";
import axiosApi from "../util/AxiosRequests";

// Page level
function DetailPage() {



    return (
        <div>
            <TopNavigation/>
            <Detail/>
        </div>
    );
}

// Detail level
function Detail() {
    const itemID = getPassedItemID();
    //const ID =getPassedItemID();
    const navigate = useNavigate();

    const [tag, setPostTags] = useState([]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axiosApi.get('/post-tag/get-all');
                setPostTags(response.data['postTags']);
                //console.log('Post Tags:', response.data['postTags']);
            } catch (error) {
                navigate('/v2/login');
                //console.error('Error fetching post tags:', error);
            }
        };

        fetchTags();
    }, [navigate]);

    let component = <DefaultPage/>;
    if (new URLSearchParams(window.location.search).get("newpost") === "true") {
        component = <NewPost tag = {tag}/>;
    }
    if(itemID !== null){
        component = <DetailInfo/>;
    }


    return (
        <div>
            <TagSelectBar tag={tag}/>

            <div className="container">
                <div className="left">
                    <PriceSelectBar/>
                    <ScrollableContainer/>
                </div>
                <div className="right">
                    {component}
                </div>
            </div>
        </div>
    );
}

function DefaultPage(){
    return (
        <h1>empty</h1>
    );
}

// Tage select bar
function TagSelectBar({tag}) {


    return (
        <div className={"select-tag scrollbar scrollbar-ho scrollbar-hidden"}>
            <ul>
                {Object.keys(tag).map((key) => (
                    <Tag appear={key} tagID={tag[key]}/>
                ))}
            </ul>
        </div>
    );
}

// Different tag
function Tag(info) {
    let url = `/v2/detail?`;
    const currentTags = getPassedTags();
    let flag = false;
    for (let i = 0; i < currentTags.length; i++) {
        if (currentTags[i] === info.tagID.toString()) {
            flag = true;
            continue;
        }
        url += "&tag=" + currentTags[i];
    }
    let IsSelect = "active";
    if (!flag) {
        url += "&tag=" + info.tagID
        IsSelect = "";
    }
    if (getPassedItemID() !== null)
        url += "&itemID=" + getPassedItemID();

    if(getMinPrice() !== null)
        url += "&minPrice=" + getMinPrice();
    if(getMaxPrice() !== null)
        url += "&maxPrice=" + getMaxPrice();

    url = url.replaceAll("?&", "?");


    return (
        <li><a className={IsSelect} href={url}>{info.appear}</a></li>
    );
}


function PriceSelectBar() {

    const OnClickE = () => {
        let inputMax = true;
        let inputMin = true;
        let minPrice = document.querySelector(".price-select input[placeholder='Min Price']").value;
        let maxPrice = document.querySelector(".price-select input[placeholder='Max Price']").value;

        inputMin = !(minPrice === "" );
        minPrice = Number(minPrice);
        minPrice = minPrice>0?minPrice:0;

        inputMax = !(maxPrice === "" );
        maxPrice = Number(maxPrice);
        maxPrice = maxPrice>0?maxPrice:0;

        if(minPrice>maxPrice && inputMin && inputMax){
            [minPrice,maxPrice] = [maxPrice,minPrice];
        }

        let url = `/v2/detail?`;
        if (inputMin) {
            url += `minPrice=${minPrice}`;
        }
        if (inputMax) {
            url += `&maxPrice=${maxPrice}`;
        }
        if (getPassedTags().length > 0) {
            url += "&tag=" + getPassedTags().join("&tag=");
        }
        if (getPassedItemID() !== null) {
            url += "&itemID=" + getPassedItemID();
        }
        url = url.replaceAll("?&", "?");
        window.location.href = url;
    }

    const minPrice = getMinPrice();
    const maxPrice = getMaxPrice();

    return (
        <div className="price-select">
            <div className={"firstLine"}>
                <input type={"number"} min={0} placeholder={"Min Price"} defaultValue={minPrice}></input> - <input type={"number"} min={0}
                                                                                           placeholder={"Max Price"} defaultValue={maxPrice}></input>
                <button onClick={OnClickE}>Apply</button>

            </div>
        </div>
    )
}


function ScrollableContainer() {
    const targetRef = useRef(null);

    //TODO: get all items from server, with pram: tag, minPrice, maxPrice. If latter we want to add search bar, we
    // should also pass the search key word

    const items = [
        { id: 1, name: "A", price: 20, tagName: ["a", "b", "c"] },
        { id: 2, name: "B1", price: 30, tagName: ["x", "y"] },
        { id: 3, name: "B12", price: 30, tagName: ["x", "y"] },
        { id: 4, name: "word word worda words", price: 30, tagName: ["x", "y"] },
        { id: 5, name: "B23", price: 30, tagName: ["x", "y"] },
        { id: 6, name: "B4", price: 3.15, tagName: ["x", "y"] },
        { id: 7, name: "B4", price: 30, tagName: ["x", "y"] },
        { id: 8, name: "B23", price: 30, tagName: ["x", "y"] },
        { id: 9, name: "B34", price: 30, tagName: ["x", "y"] },
        { id: 10, name: "B32", price: 30, tagName: ["x", "y"] },
        { id: 11, name: "B46", price: 30, tagName: ["x", "y"] },
        { id: 12, name: "B3", price: 30, tagName: ["x", "y"] },
        { id: 13, name: "B4", price: 30, tagName: ["x", "y"] },
        { id: 14, name: "B13", price: 30, tagName: ["x", "y"] },
    ];

    useEffect(() => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
        }
    }, []);

    return (
        <div className="scrollable-container scrollbar scrollbar-vo">
            {items.map((item, index) => (
                <SelectBar
                    key={item.id}
                    ref={index.toString() === getPassedItemID() ? targetRef : null} // 对第一个 SelectBar 传递 ref
                    {...item} // 解构传递 item 的所有属性
                />
            ))}
        </div>
    );
}


const SelectBar = forwardRef(({ id, name, price, tagName }, ref) => {
    const tag = tagName || [];
    let className = "";

    if (getPassedItemID() === id.toString()) {
        className = "Select";
    }

    let url = `/v2/detail?itemID=${id}`;
    if (getPassedTags().length > 0) {
        url += "&tag=" + getPassedTags().join("&tag=");
    }

    if(getMinPrice() !== null)
        url += "&minPrice=" + getMinPrice();
    if(getMaxPrice() !== null)
        url += "&maxPrice=" + getMaxPrice();

    return (
        <div ref={ref} className={`select SelectBar ${className}`}>
            <a href={url}>
                <div className="upper">
                    <h4 >{name}</h4>
                    <h2>${price}</h2>
                </div>
                <p>{Array.isArray(tag) ? tag.join(" ") : tag}</p>
            </a>
        </div>
    ); //Maybe we can improve the appearance of the tag
});


function getPassedItemID() {
    return new URLSearchParams(window.location.search).get("itemID");
}

function getPassedTags() {
    return new URLSearchParams(window.location.search).getAll("tag");
}

function getMinPrice() {
    return new URLSearchParams(window.location.search).get("minPrice");
}

function getMaxPrice() {
    return new URLSearchParams(window.location.search).get("maxPrice");
}

export default DetailPage;