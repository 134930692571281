// `frontend/src/pages/Register.js`
import React, {useState} from 'react';
import StringInputText from "../components/StringInputText";
import {useNavigate} from "react-router-dom";
import axiosApi from "../util/AxiosRequests";

function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useNavigate();

    const handleRegisterStage1 = async (uname, em) => {
        console.log(uname, em);
        try {
            const response = await axiosApi.post('/users/register-stage-1',
                {
                    username: uname,
                    email: em
                })
            console.log(response);
            alert('Verification code sent');
        } catch (error) {
            console.error(error);
            alert('User already exists');
        }
    }

    const handleRegisterStage2 = async (uname, vcode, pwd) => {
        console.log(uname, vcode, pwd);
        try {
            const response = await axiosApi.post('/users/register-stage-2', {
                username: uname,
                verificationCode: vcode,
                password: pwd
            });
            console.log(response);
            navigate('/v2/login');
            alert('Registration successful');
        } catch (error) {
            console.error(error);
            alert('Registration failed');
        }
    }

    return (<>
        <StringInputText label={"Username"} onChange={(e) => setUsername(e.target.value)}/>
        <StringInputText label={"Email"} onChange={(e) => setEmail(e.target.value)}/>
        <button onClick={() => handleRegisterStage1(username, email)}>Get Verification Code</button>

        <StringInputText label={"Verification Code"} onChange={(e) => setVerificationCode(e.target.value)}/>
        <StringInputText label={"Password"} onChange={(e) => setPassword(e.target.value)}/>
        <button onClick={() => handleRegisterStage2(username, verificationCode, password)}>Register</button>
    </>);
}

export default Register;