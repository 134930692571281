import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import Root from './pages/Root';
import Register from './pages/Register';
import Login from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/Home";
import Dictionary from "./pages/Dictionary";
import Detail from "./pages/Detail";
import DetailPage from "./pages/Detail";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/v2" />} />
                <Route path="/v2" element={<Root/>}/>
                <Route path="/v2/register" element={<Register/>}/>
                <Route path="/v2/login" element={<Login/>}/>
                <Route path="/v2/home" element={<Home/>}/>
                <Route path="/v2/dictionary" element={<Dictionary/>}/>
                <Route path="/v2/detail" element={<DetailPage/>}/>
            </Routes>
        </Router>
    );
};

export default App;