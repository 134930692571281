// `frontend/src/pages/Root.js`
import React from 'react';
import {useNavigate} from 'react-router-dom';
import axiosApi from "../util/AxiosRequests";
import TopNavigation from "../components/TopNavigation";

const Root = () => {
    const navigate = useNavigate();

    function handleEmptyRequest() {
        axiosApi.get('/test/empty').then(response => {
            alert('Empty request sent');
            console.log(response);
        })
    }

    function handleRandomNumber() {
        axiosApi.get('/test/random').then(response => {
            alert('Random number received');
            console.log(response)
        })
    }

    return (
        <div>
            <TopNavigation/>
            <h1>Welcome</h1>
            <button onClick={() => navigate('/v2/register')}>Register</button>
            <button onClick={() => navigate('/v2/login')}>Login</button>
            <button onClick={handleEmptyRequest}>Send Empty Request</button>
            <button onClick={handleRandomNumber}>Get Random Number</button>
            <button onClick={() => navigate('/v2/dictionary')}>Dictionary</button>
        </div>
    );
};

export default Root;