
//TODO: Replace the placeholder text with the actual component

function DetailInfo() {
    return (
        <div>
            <h2>Detailed Information</h2>
            <p>Should be replaced by react component</p>
        </div>
    );
}

function getPassedItemID() {
    return new URLSearchParams(window.location.search).get("itemID");
}

export default DetailInfo;